.field-gallery{

  .gallery{
    display: flex;
    flex-wrap: wrap;

    .image{
      position: relative;
      width: 20%;
      padding: 2px;

      &:hover{
        img{
          opacity: 0.5;
        }
        .remove-image{
          opacity: 1;
        }
      }

      img{
        width: 100%;
        height: auto;
        transition: $transition-fade;
      }

      .remove-image{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: $light;
        color: $danger;
        width: 30px;
        height: 30px;
        vertical-align: middle;
        text-align: center;
        border-radius: 50%;
        opacity: 0;
        transition: $transition-base;

        &:hover{
          background: $danger;
          color: $light;
        }

        i{
          line-height: 30px;
        }
      }
    }
  }

}