.bootstrap-tagsinput {
    background-color: #fff;
    border: 1px solid $gray-200;
    box-shadow: inset 0 1px 1px rgba(black, 0.075);
    display: inline-block;
    padding: 4px 6px;
    color: $gray-800;
    vertical-align: middle;
    border-radius: 4px;
    width: 100%;
    line-height: 22px;
    cursor: text;


    input {
        border: none;
        box-shadow: none;
        outline: none;
        background-color: transparent;
        padding: 0 6px;
        margin: 0;
        width: 100px;
        max-width: inherit;
    }

    &.form-control{
        input{

            &::placeholder {
                color: #777;
                opacity: 1;
            }

            &:focus {
                border: none;
                box-shadow: none;
            }

        }
    }


    .badge {
        margin: 2px 0;
        padding:5px 8px;


        [data-role="remove"] {
            margin-left: 8px;
            cursor: pointer;

            &:after {
                content: "Ã";
                padding: 0 4px;
                background-color:rgba(black, 0.1);
                border-radius:50%;
                font-size:13px
            }


            &:hover {

                &:after {
                    background-color: rgba(0, 0, 0, 0.62);
                }

                &:active {
                    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
                }
            }
        }
    }

}


.tt-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 1000;
    display: none;
    float: left;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    list-style: none;
    font-size: 14px;
    background-color: #ffffff;
    border: 1px solid #cccccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    background-clip: padding-box;
    cursor: pointer;
}

.tt-suggestion {
    display: block;
    padding: 3px 20px;
    clear: both;
    font-weight: normal;
    line-height: 1.428571429;
    color: #333333;
    white-space: nowrap;
}

.tt-suggestion:hover,
.tt-suggestion:focus {
    color: #ffffff;
    text-decoration: none;
    outline: 0;
    background-color: #428bca;
}
