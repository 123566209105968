.tox-tinymce{
  border: none;
}

.note-editing-area{

  ul{
    list-style: none;
    padding-left: 22px;

    li{
      position: relative;
      margin-bottom: 15px;

      &:after{
        content: "\f058";
        font-family: "Font Awesome 5 Free";
        position: absolute;
        left: -23px;
        top: -1px;
        font-weight: 900;
        color: $success;
        font-size: 1rem;
      }
    }

    ul{
      margin-top: 17px;
    }
  }
}

.note-toolbar{
  position: sticky;
  top: 52px;
  z-index: 100;
}
