// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import url('~@fortawesome/fontawesome-free/css/all.css');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';


// Select2
@import '~select2';

// Summernote
@import '~summernote/dist/summernote-bs4.css';

// Bootstrap 4 Tags Input
// @import '~bootstrap4-tagsinput/tagsinput.css'; // Copied to local for modifications.



@import 'modules/bootstrap4-tagsinput';


@import 'components/all';
@import 'fields/all';


$sidebar-width: 180px;

.page-wrap{

  display: flex;
  align-items: stretch;

  .sidebar{
    background: $dark;
    color: $light;
    width: $sidebar-width;
    min-width: $sidebar-width;

    .brand{
      display: block;
      padding: 0.6rem 1rem;
      color: $light;
      text-decoration: none;
      font-size: 1.1rem;
      border-bottom: $gray-700 solid 1px;
    }


    .menu{
      margin: 0;

      .icon{
        width: 1.3em;
        text-align: center;
        display: inline-block;
        margin-right: 0.1rem;
        font-size: 0.9em;
      }

      &, ul{
        display: flex;
        flex-direction: column;
        padding: 0;
        list-style: none;
      }

      ul{
        display: none;
      }

      li{
        position: relative;

        &:hover{
          background: rgba(white, 0.05);

          > a{
            color: $gray-200;
          }
        }

        a{
          display: block;
          padding: 0.6rem 1rem;
          text-decoration: none;
          color: $gray-500;
        }


        &:not(.active):hover{

          > ul {
            display: block;
            background: $dark;
            color: $light;
            width: $sidebar-width;
            position: absolute;
            z-index: 9999;
            left: 100%;
            top: 0;
          }
        }


        &.active{
          background: rgba( white, 0.1 );

          > ul{
            display: block;
          }

          > a{
            background: $primary;
            color: $light;
          }
        }

      }

      .divider{
        border-top: rgba($gray-500, 0.1) solid 1px;
        margin: 0.1rem 0;
        height: 0;
      }

    }
  }

  .page-container{
    // margin-left: $sidebar-width;
    min-height: 100vh;
    flex-grow: 1;
  }


  .header{
    background: $gray-200;
    color: $dark;
    padding: 0.4rem 0;
    margin-bottom: 0.5rem;

    a{
      color: $dark;
    }

    .header-nav{
      display: flex;
      align-items: center;
    }
  }

}



.list-comma{
  list-style: none;
  margin: 0;
  padding: 0;

  li{
    display: inline;

    &:after{
      content: ', ';
      display: inline;
    }

    &:last-child{
      &:after{
        display: none;
      }
    }
  }
}


.list-actions{
  @extend .list-comma;

  li{
    &:after{
      margin: 0 2px;
      content: ' | ';
      color: rgba(black, 0.2);
    }

    a{
      text-decoration: none;
    }
  }
}


.model-table{

  thead:first-child{
    tr{
      > * {
        @extend .border-0;
      }
    }
  }

  .list-actions{
    opacity: 0;
    transition: opacity 0.3s;

    .icon{
      font-size: 0.87em;
    }
  }


  tr:hover{
    .list-actions{
      opacity: 1;
    }
  }

}

.settings-wrap{
    column-count: 3;

    .card{
        display: inline-block;
        margin-bottom: 30px;
        width: 100%;
    }
}



.bootstrap-tagsinput{
    .badge{
        margin-right: 4px;
    }
}


.is-invalid{
    .bootstrap-tagsinput{
        border-color: $form-feedback-invalid-color;
    }
}


.overflow-image{
  max-width: 100%;
  margin: 0.5rem 0 0.5rem 0;
  border-radius: 5px;
  box-shadow: rgba(black, 0.25) 0 2px 15px;
}


.wp-block{
  max-width: 1024px;
}
